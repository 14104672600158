import './App.css'
import './i18n/i18n'

import { Route, Routes } from 'react-router-dom'
import AdminDistributeAssetsPage from './pages/AdminDistributeAssetsPage/AdminDistributeAssetsPage'
import AdminNotificationsPage from './pages/AdminNotificationsPage/AdminNotificationsPage'
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import LoginPage from './pages/LoginPage/LoginPage'
import { PageNotFound } from './utils/hooks/PageNotFound'
import { RequireAdminRole } from './utils/hooks/RequireAdminRole'
import { RequireAuth } from './utils/hooks/RequireAuth'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import UserAccountPage from './pages/UserAccountPage/UserAccountPage'
import { AppContext } from './contexts/AppContext'
import { UserLoggedIn } from './types/UserLoggedIn'
import WalletPage from './pages/WalletPage/WalletPage'
import WalletAddressSettingsPage from './pages/WalletAddressSettingsPage/WalletAddressSettingsPage'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import AdminDistributeCheckpointPage from './pages/AdminDistributeCheckpointPage/AdminDistributeCheckpointPage'
import { useState, useEffect } from 'react'
import AdminDistributeDetailsPage from './pages/AdminDistributeDetailsPage/AdminDistributeDetailsPage'
import UpdateVersionPage from './pages/UpdateVersionPage/UpdateVersionPage'
import AdminImportPrivateKeyPage from './pages/AdminImportPrivateKeyPage/AdminImportPrivateKeyPage'
import { PaymentWalletStatus } from './types/PaymentWalletStatus'
import { useQuery } from 'react-query'
import PaymentWalletService from './api/services/PaymentWalletService/PaymentWalletService'
import QueryKeys from './utils/QueryKeys'
import UserAssetDetails from './pages/UserAssetDetailsPage/UserAssetDetailsPage'
import AdminUserListManagementPage from './pages/AdminUserListManagementPage/AdminUserListManagementPage'
import AdminUserManagementPage from './pages/AdminUserManagementPage/AdminUserManagementPage'
import AdminUserAddPage from './pages/AdminUserAddPage/AdminUserAddPage'
import AdminTokensPage from './pages/AdminTokensPage/AdminTokensPage'

function App() {
  const localStorageUser = localStorage.getItem('minando-user')
  const [user, setUser] = useState<UserLoggedIn | null>(
    localStorageUser ? JSON.parse(localStorageUser) : null
  )
  const [redirectRoute, setRedirectRoute] = useState<string>('')

  const paymentWalletStatusResponse = useQuery<PaymentWalletStatus, Error>(
    QueryKeys.getFetchPaymentWalletStatusKey(),
    async () => {
      return await PaymentWalletService.getPaymentWalletStatus()
    },
    { enabled: Boolean(user) && user?.isAdmin }
  )

  const [paymentWalletStatus, setPaymentWalletStatus] = useState<PaymentWalletStatus | null>(
    paymentWalletStatusResponse?.data || null
  )

  useEffect(() => {
    setPaymentWalletStatus(paymentWalletStatusResponse?.data || null)
  }, [paymentWalletStatusResponse])

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <AppContext.Provider
        value={{
          user,
          setUser,
          paymentWalletStatus,
          setPaymentWalletStatus,
          redirectRoute,
          setRedirectRoute,
        }}
      >
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login/:email" element={<LoginPage />} />
          <Route path="/reset-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password/:email/:token" element={<ResetPasswordPage />} />
          <Route path="/user-wallet">
            <Route
              path=""
              element={
                <RequireAuth
                  isAuthenticated={Boolean(user)}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <WalletPage />
                </RequireAuth>
              }
            />
            <Route
              path="wallet-settings"
              element={
                <RequireAuth
                  isAuthenticated={Boolean(user)}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <WalletAddressSettingsPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/personal-info"
            element={
              <RequireAuth
                isAuthenticated={Boolean(user)}
                userId={user?.id}
                mustChangePassword={user?.mustChangePassword}
              >
                <UserAccountPage />
              </RequireAuth>
            }
          />
          <Route
            path="/change-password"
            element={
              <RequireAuth isAuthenticated={Boolean(user)} userId={user?.id}>
                <ChangePasswordPage />
              </RequireAuth>
            }
          />
          <Route
            path="/:asset/details/"
            element={
              <RequireAuth isAuthenticated={Boolean(user)} userId={user?.id}>
                <UserAssetDetails />
              </RequireAuth>
            }
          />
          <Route path="/admin">
            <Route
              path="notify"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminNotificationsPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="distribute/:dateFrom/:dateTo"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminDistributeAssetsPage />
                </RequireAdminRole>
              }
            />
            )
            <Route
              path="checkpoints"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminDistributeCheckpointPage />
                </RequireAdminRole>
              }
            />
            <Route
              path=":assetId/distribute/:distributionId"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminDistributeDetailsPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="private-key"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminImportPrivateKeyPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="users"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminUserListManagementPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="users/add"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminUserAddPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="users/:userId"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminUserManagementPage />
                </RequireAdminRole>
              }
            />
            <Route
              path="tokens"
              element={
                <RequireAdminRole
                  isAuthenticated={Boolean(user)}
                  isAdmin={user?.isAdmin}
                  userId={user?.id}
                  mustChangePassword={user?.mustChangePassword}
                >
                  <AdminTokensPage />
                </RequireAdminRole>
              }
            />
          </Route>
          <Route path="/update-version" element={<UpdateVersionPage />} />
          <Route
            path="/*"
            element={<PageNotFound isAuthenticated={Boolean(user)} userId={user?.id} />}
          ></Route>
        </Routes>
      </AppContext.Provider>
    </LocalizationProvider>
  )
}

export default App
