import bitcoinLogo from '../../assets/images/logo-bitcoin.svg'
import minenLogo from '../../assets/images/logo-minando-white.svg'
import Constants from '../Constants'

const assetLogos = new Map([
  [Constants.BITCOIN_SYMBOL, bitcoinLogo],
  [Constants.MINEN_SYMBOL, minenLogo],
])

const getAssetLogoSrc = (asset: string) => {
  return assetLogos.get(asset.toUpperCase()) || ''
}

export default getAssetLogoSrc
