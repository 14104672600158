import LoginForm from '../../components/LoginForm/LoginForm'
import minandoLogo from '../../assets/images/logo-minando-black.svg'
import { Container, Grid } from '@mui/material'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { UserLoggedIn } from '../../types/UserLoggedIn'
import { Navigate, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

export default function LoginPage() {
  const { user, setUser, redirectRoute, setRedirectRoute } = useContext(AppContext)
  const navigate = useNavigate()
  const { email } = useParams()

  const onLogin = (user: UserLoggedIn) => {
    setUser(user)
    redirectRoute ? navigate(redirectRoute) : navigate(`/user-wallet`)
    localStorage.setItem('minando-user', JSON.stringify(user))
    setRedirectRoute('')
  }

  if (user && !redirectRoute) {
    return <Navigate to="/user-wallet" replace />
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
      >
        <img src={minandoLogo} height="210px" alt="logo" style={{ marginBottom: 20 }} />
        <Grid item>
          <LoginForm email={email} onSuccess={(user: UserLoggedIn) => onLogin(user)} />
        </Grid>
      </Grid>
    </Container>
  )
}
