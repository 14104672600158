import minandoLogo from '../../assets/images/logo-minando-black.svg'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

export default function UpdateVersionPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
      >
        <img src={minandoLogo} height="210px" alt="logo" style={{ marginBottom: 15 }} />
        <Typography variant="body1" textAlign="justify">
          {t('Se ha actualizado la web a la ultima versión!')}
        </Typography>
        <Typography variant="body1" textAlign="justify">
          {t('Por favor ingrese nuevamente')}
        </Typography>
        <Button variant="contained" size="small" sx={{ mt: 3 }} onClick={() => navigate('/login')}>
          {t('Iniciar Sesión')}
        </Button>
      </Grid>
    </Container>
  )
}
