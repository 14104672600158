const GRAY_RGB = 'rgb(237, 237, 237)'
const BITCOIN_SYMBOL = 'BTC'
const MINEN_SYMBOL = 'MINEN'
const BITCOIN_DECIMALS = 8
const MINEN_DECIMALS = 18
const REQUIRED_FIELD_TEXT = 'Este campo es requerido'
const MINANDO_WEB_CLIENT = 'MinandoWebClient'

const Constants = {
  GRAY_RGB,
  BITCOIN_SYMBOL,
  MINEN_SYMBOL,
  BITCOIN_DECIMALS,
  REQUIRED_FIELD_TEXT,
  MINANDO_WEB_CLIENT,
  MINEN_DECIMALS,
}

export default Constants
