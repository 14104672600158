import Constants from '../Constants'

const decimalsMap: Map<string, number> = new Map<string, number>()
decimalsMap.set(Constants.BITCOIN_SYMBOL, Constants.BITCOIN_DECIMALS)
decimalsMap.set(Constants.MINEN_SYMBOL, Constants.MINEN_DECIMALS)

const DEFAULT_DECIMALS = 8

const getTokenDecimals = (asset: string) => {
  return decimalsMap.get(asset.toUpperCase()) || DEFAULT_DECIMALS
}

export default getTokenDecimals
