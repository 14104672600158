const PAYMENT_WALLET_URL = '/payment-wallet/minen'

export const getPaymentWalletStatusUrl = () => {
  return `${PAYMENT_WALLET_URL}/status`
}

export const getImportPrivateKeyUrl = () => {
  return PAYMENT_WALLET_URL
}

export const getUnlockPaymentWalletUrl = () => {
  return `${PAYMENT_WALLET_URL}/unlock`
}

export const getLockPaymentWalletUrl = () => {
  return `${PAYMENT_WALLET_URL}/lock`
}

export const getPaymentWalletBalanceUrl = () => {
  return `${PAYMENT_WALLET_URL}/balance`
}
