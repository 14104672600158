import { Grid, Skeleton, Typography } from '@mui/material'
import { t } from 'i18next'
import AdminCreateTokenForm from '../../components/AdminCreateTokenForm/AdminCreateTokenForm'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import { TokenData } from '../../types/TokenData'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../contexts/AppContext'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery } from 'react-query'
import AdminTokenList from '../../components/AdminTokenList/AdminTokenList'
import SearchBar from '../../components/SearchBar/SearchBar'

export default function AdminTokensPage() {
  const [filteredTokens, setFilteredTokens] = useState<TokenData[]>([])
  const [openSnackbar] = useSnackbar()
  const { user } = useContext(AppContext)
  const tokensResponse = useQuery(
    QueryKeys.getFetchTokensKey(),
    async () => await AssetsService.getTokens(),
    { enabled: Boolean(user?.isAdmin) }
  )

  useEffect(() => {
    setFilteredTokens(tokensResponse.data || [])
  }, [tokensResponse.data])

  const onTokenCreation = async (tokenData: TokenData) => {
    const didSucceed = await AssetsService.createToken(tokenData)
    if (didSucceed) {
      openSnackbar({
        autoHideDuration: 5000,
        message: t('El token ha sido creado con éxito.'),
        severity: 'success',
      })
      tokensResponse.refetch()
    } else {
      openSnackbar({
        autoHideDuration: 3000,
        message: t('Algo salió mal. Por favor reintente.'),
        severity: 'error',
      })
    }
    return didSucceed
  }

  const getSymbols = (tokens: TokenData[]) => {
    return tokens.map((token) => token.symbol)
  }

  const filterTokens = (searchString: string) => {
    searchString = searchString.toLowerCase()
    const filteredList = tokensResponse?.data?.filter(
      (tokenData) =>
        tokenData.symbol?.toLowerCase().includes(searchString) ||
        tokenData.name?.toLowerCase().includes(searchString)
    )
    setFilteredTokens(filteredList || [])
  }

  return (
    <Grid container sx={{ flexDirection: 'column', alignItems: 'center', mb: 2 }}>
      <Grid item sx={{ width: '100%' }}>
        <AdminCreateTokenForm
          onSubmitForm={onTokenCreation}
          symbols={getSymbols(tokensResponse.data || [])}
        />
        <Typography variant="h5" mb={2} mt={2}>
          {t('Tokens')}
        </Typography>
        {tokensResponse.isLoading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
        ) : (
          <Grid container item justifyContent="end">
            <SearchBar filterFunction={filterTokens} />
            <Grid item xs={12}>
              <AdminTokenList tokens={filteredTokens} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
