import SymbolInformation from '../../components/SymbolInformation/SymbolInformation'
import { SymbolData } from '../../types/SymbolData'
import UserWalletContainer from '../../components/UserWallet/UserWalletContainer'
import minangoLogo from '../../assets/images/logo-minando-black.svg'
import bitcoinLogo from '../../assets/images/logo-bitcoin.svg'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useMemo } from 'react'
import { AppContext } from '../../contexts/AppContext'
import AssetActions from '../../components/AssetActions/AssetActions'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/QueryKeys'
import Constants from '../../utils/Constants'
import { Asset } from '../../types/Asset'
import getTokenDecimals from '../../utils/functions/getTokenDecimals'

const minenSymbolData: SymbolData = {
  symbol: Constants.MINEN_SYMBOL,
  symbolName: 'Minando',
  symbolImageSrc: minangoLogo,
  showChart: false,
}

const btcSymbolData: SymbolData = {
  symbol: Constants.BITCOIN_SYMBOL,
  symbolName: 'Bitcoin',
  symbolImageSrc: bitcoinLogo,
  showChart: true,
}

const getAssetsAvailableAmounts = (assets: any): Map<string, number> => {
  const availableAmounts = new Map()
  for (let assetId in assets) {
    const asset: Asset = assets[assetId]
    availableAmounts.set(
      assetId,
      Number(
        (asset.tokenAmountAssigned - asset.tokenAmountDelivered).toFixed(
          getTokenDecimals(asset.tokenId)
        )
      )
    )
  }
  return availableAmounts
}

export default function WalletPage() {
  const { t } = useTranslation()
  const { user } = useContext(AppContext)
  const userId = user ? user.id : ''
  const userAssetsResponse = useQuery(
    QueryKeys.getFetchUserAssetsKey(userId),
    async () => {
      return await AssetsService.getAssets(userId)
    },
    { enabled: Boolean(user) }
  )

  const assets = [btcSymbolData, minenSymbolData]
  const assetsAvailableAmounts = useMemo(
    () =>
      userAssetsResponse.data ? getAssetsAvailableAmounts(userAssetsResponse.data) : new Map(),
    [userAssetsResponse.data]
  )

  return (
    <Box>
      <Card sx={{ minWidth: 275 }} elevation={0}>
        <CardContent sx={{ p: 0 }}>
          <Typography
            variant="h6"
            align="left"
            mb={2}
            sx={{ backgroundColor: '#afafaf', paddingLeft: '5px' }}
          >
            {t('Mis criptos')}
          </Typography>
          {assets.map((asset) => {
            return (
              <Box
                key={`${asset.symbol}-tenency`}
                sx={{ border: 1, borderRadius: '16px', padding: '5px', mb: 2 }}
              >
                <UserWalletContainer
                  symbolData={asset}
                  userId={userId}
                  assetActions={
                    <AssetActions
                      availableAmount={assetsAvailableAmounts.get(asset.symbol) || 0}
                      asset={asset.symbol}
                    />
                  }
                />
              </Box>
            )
          })}
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275 }} elevation={0}>
        <CardContent sx={{ p: 0 }}>
          <Typography
            variant="h6"
            align="left"
            mb={2}
            sx={{ backgroundColor: '#afafaf', paddingLeft: '5px' }}
          >
            {t('Información de tokens')}
          </Typography>
          {assets.map((asset) => {
            return (
              <Box
                key={`${asset.symbol}-info`}
                sx={{ border: 1, borderRadius: '16px', padding: '5px', mb: 2 }}
              >
                <SymbolInformation symbolData={asset} showChart={Boolean(asset.showChart)} />
              </Box>
            )
          })}
        </CardContent>
      </Card>
    </Box>
  )
}
