import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm'
import minandoLogo from '../../assets/images/logo-minando-black.svg'
import { Container, Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { Navigate, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function ForgotPasswordPage() {
  const { user } = useContext(AppContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (user) {
    return <Navigate to="/user-wallet" replace />
  }

  return (
    <>
      <ArrowBackIcon sx={{ padding: '15px' }} onClick={() => navigate(-1)} />
      <Container maxWidth="lg">
        <Grid container justifyContent="center" flexDirection="column" alignItems="center">
          <img src={minandoLogo} height={'150px'} alt="logo" />
          <Typography gutterBottom variant="h5" align="center">
            {t('¿Tienes problemas para iniciar sesión?')}
          </Typography>
          <Typography variant="body2" align="center" color="text.secondary">
            {t(
              'Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.'
            )}
          </Typography>
          <ForgotPasswordForm />
        </Grid>
      </Container>
    </>
  )
}
