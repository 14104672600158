import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useFormikContext } from 'formik'
import { t } from 'i18next'
import { useState } from 'react'
import Constants from '../../utils/Constants'

interface ConfirmationDistributeAssetsDialogProps {
  show: boolean
  BTCPerMinen?: number
  unitMINEN?: number
  onConfirm: Function
  onClose: Function
}

export default function ConfirmationDistributeAssetsDialog(
  props: ConfirmationDistributeAssetsDialogProps
) {
  const { resetForm } = useFormikContext()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onConfirm = async () => {
    setIsSubmitting(true)
    await props.onConfirm()
    resetForm()
  }

  return (
    <Dialog open={props.show}>
      <DialogTitle>{t('Atención').toLocaleUpperCase()}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Confirmar BTC Distribucion', {
            BTCPerMinen: props.BTCPerMinen?.toFixed(Constants.BITCOIN_DECIMALS),
            unitMINEN: props.unitMINEN,
          })}
        </DialogContentText>
      </DialogContent>
      {isSubmitting ? (
        <CircularProgress sx={{ alignSelf: 'center', mb: 2 }} size={26} color="primary" />
      ) : (
        <DialogActions>
          <Button onClick={() => props.onClose()}>{t('Cancelar')}</Button>
          <Button onClick={onConfirm}>{t('Confirmar')}</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
