const GET_USER_ASSETS = '/assets'
const CALCULATE_BTC_DISTRIBUTION = '/assets/btc/calculate-distribution'
const DISTRIBUTE_BTC = '/assets/btc/distributions'
const ASK_FOR_RECEIVE = '/ask-for-receive'
const TOTAL_TENENCY = '/total-tenency'
const TOKENS = '/assets/tokens'

export const geAssetsByUserId = (id: string): string => {
  return `${GET_USER_ASSETS}/${id}`
}

export const getCalculateBTCDistributionURL = (amountToDistribute: number): string => {
  return `${CALCULATE_BTC_DISTRIBUTION}/${amountToDistribute}`
}

export const getDistributeBTCURL = (): string => {
  return `${DISTRIBUTE_BTC}`
}

export const getAskForReceiveUrl = (id: string, tokenId: string) => {
  return `${GET_USER_ASSETS}/${id}${ASK_FOR_RECEIVE}?tokenId=${tokenId}`
}

export const getTotalTokenTenencyUrl = (tokenId: string) => {
  return `${GET_USER_ASSETS}/${tokenId}${TOTAL_TENENCY}`
}

export const getDistributionUrl = (tokenId: string, assetDistributionId: string) => {
  return `/assets/${tokenId}/distributions/${assetDistributionId}`
}

export const getDistributionsUrl = (tokenId: string, userId?: string) => {
  const distributionUrl = `/assets/${tokenId}/distributions`
  if (userId) {
    return `${distributionUrl}?userId=${userId}`
  }
  return distributionUrl
}

export const getAssetDistributionsUrl = (asset: string, userId?: string) => {
  if (userId) {
    return `${GET_USER_ASSETS}/${asset}/distributions?userId=${userId}`
  }
  return `${GET_USER_ASSETS}/${asset}/distributions`
}

export const getSendAssetToUserUrl = (userId: string, distributionId: string) => {
  return `/assets/${userId}/distributions/${distributionId}`
}

export const getSendPendingAssetToUserUrl = (userId: string) => {
  return `/assets/${userId}/distributions`
}

export const getSendAssetToAllUsersUrl = (distributionId: string) => {
  return `/assets/all-users/distributions/${distributionId}`
}

export const getAssignAssetsUrl = (userId: string): string => {
  return `${GET_USER_ASSETS}/${userId}`
}

export const getCreateTokenUrl = () => {
  return `${TOKENS}`
}

export const getTokensUrl = (userId?: string) => {
  return `${TOKENS}?userId=${userId}`
}
