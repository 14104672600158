import AdminDistributeAssetsForm from '../../components/AdminDistributeAssetsForm/AdminDistributeAssetsForm'
import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import { useParams } from 'react-router'

export default function AdminDistributeAssetsPage() {
  const { dateFrom, dateTo } = useParams()
  const initialDateFrom = new Date(dateFrom || '')
  const initialDateTo = new Date(dateTo || '')

  const onSubmitAssetsForm = async (totalAmount: number) => {
    return await AssetsService.calculateDistribution(totalAmount)
  }

  return (
    <Grid container>
      <Grid item width="100%">
        <Typography variant="h5" mb={2}>
          {t('Distribucion de BTC')}
        </Typography>
        <AdminDistributeAssetsForm
          onSubmitForm={(totalAmount: number) => onSubmitAssetsForm(totalAmount)}
          initialDateFrom={initialDateFrom}
          initialDateTo={initialDateTo}
        />
      </Grid>
    </Grid>
  )
}
