const FETCH_PRICE_KEY = 'fetch-price'
const FETCH_VARIATION_KEY = 'fetch-variation'
const FETCH_PRICE_HISTORY_KEY = 'fetch-history'
const FETCH_USER_KEY = 'fetch-user'
const FETCH_USERS_KEY = 'fetch-users'
const FETCH_USER_ASSETS_KEY = 'fetch-user-assets'
const FETCH_USER_WALLETS_KEY = 'fetch-user-wallets'
const FETCH_TOTAL_TOKEN_TENENCY = 'fetch-total-token-tenency'
const FETCH_ASSET_DISTRIBUTED_KEY = 'fetch-asset-distributed'
const FETCH_ASSETS_DISTRIBUTIONS_KEY = 'fetch-assets-distributions'
const FETCH_PAYMENT_WALLET_STATUS = 'fetch-payment-wallet-status'
const FETCH_USER_IS_ADMIN_KEY = 'fetch-user-is-admin'
const FETCH_TOKENS = 'fetch-tokens'
const FETCH_PAYMENT_WALLET_BALANCE = 'fetch-payment-wallet-balance'

const getFetchPriceKey = (tokenId: string): string => {
  return `${FETCH_PRICE_KEY}-${tokenId}`
}

const getFetchVariationKey = (tokenId: string): string => {
  return `${FETCH_VARIATION_KEY}-${tokenId}`
}

const getFetchPriceHistoryKey = (tokenId: string, timeframe: string): string => {
  return `${FETCH_PRICE_HISTORY_KEY}-${tokenId}-${timeframe}`
}

const getFetchUserKey = (userId: string): string => {
  return `${FETCH_USER_KEY}-${userId}`
}

const getFetchUsersKey = (): string => {
  return `${FETCH_USERS_KEY}`
}

const getFetchUserAssetsKey = (userId: string): string => {
  return `${FETCH_USER_ASSETS_KEY}-${userId}`
}

const getFetchUserWalletsKey = (userId: string, tokenId: string): string => {
  return `${FETCH_USER_WALLETS_KEY}-${userId}-${tokenId}`
}

const getFetchTotalTokenTenencyKey = (tokenId: string): string => {
  return `${FETCH_TOTAL_TOKEN_TENENCY}-${tokenId}`
}

const getFetchAssetDistributionKey = (assetDistributionId: string): string => {
  return `${FETCH_ASSET_DISTRIBUTED_KEY}-${assetDistributionId}`
}

const getFetchAssetsDistributionsKey = (tokenId: string, userId?: string): string => {
  return `${FETCH_ASSETS_DISTRIBUTIONS_KEY}-${tokenId}-${userId}`
}

const getFetchPaymentWalletStatusKey = (): string => {
  return `${FETCH_PAYMENT_WALLET_STATUS}`
}

const getFetchAuthUserKey = (userId: string): string => {
  return `${FETCH_USER_IS_ADMIN_KEY}-${userId}`
}

const getPaymentWalletBalanceKey = (): string => {
  return `${FETCH_PAYMENT_WALLET_BALANCE}`
}

const getFetchTokensKey = (userId?: string): string => {
  return `${FETCH_TOKENS}-${userId}`
}

const QueryKeys = {
  getFetchPriceKey,
  getFetchVariationKey,
  getFetchPriceHistoryKey,
  getFetchUserKey,
  getFetchUsersKey,
  getFetchUserAssetsKey,
  getFetchUserWalletsKey,
  getFetchTotalTokenTenencyKey,
  getFetchAssetDistributionKey,
  getFetchAssetsDistributionsKey,
  getFetchPaymentWalletStatusKey,
  getFetchAuthUserKey,
  getPaymentWalletBalanceKey,
  getFetchTokensKey,
}

export default QueryKeys
