import { Avatar, Badge, Box, Snackbar, Tooltip, Typography } from '@mui/material'
import { Badge as BadgeIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { User } from '../../types/User'
import { useState } from 'react'
import UserProfileWalletAddress from './UserProfileWalletAddress'
import { AssetWallet } from '../../types/AssetWallet'

interface UserProfileProps {
  user: User
  isAdmin: boolean
  wallets: AssetWallet[]
}

export default function UserProfile(props: UserProfileProps) {
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  const handleCopyToClipboard = (textToCopy?: string) => {
    navigator.clipboard.writeText(textToCopy || '')
    setOpenSnackbar(true)
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Tooltip title={props.isAdmin ? t('Este usuario es administrador').toString() : ''}>
          <Badge
            color="success"
            badgeContent={<BadgeIcon fontSize="small" />}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            overlap="circular"
            invisible={!props.isAdmin}
          >
            <Avatar
              sx={{
                height: 64,
                width: 64,
              }}
            />
          </Badge>
        </Tooltip>
        <Typography color="textPrimary" gutterBottom variant="h5" mt={2}>
          {`${props.user.userName.name} ${props.user.userName.lastName}`}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {props.user.email}
        </Typography>
        {props.wallets.map((wallet) => (
          <UserProfileWalletAddress
            asset={wallet.asset || ''}
            handleCopyToClipboard={handleCopyToClipboard}
            walletAddress={wallet.walletAddress}
            key={wallet.asset}
          />
        ))}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={t('¡Copiado al portapapeles!')}
      />
    </>
  )
}
