import { Paper, Card, CardContent, Skeleton } from '@mui/material'
import Constants from '../../utils/Constants'
import { makeStyles } from '@mui/styles'
import SendAssetToUserWithoutActiveWallet from '../SendAssetToUserWithoutActiveWallet/SendAssetToUserWithoutActiveWallet'
import { DistributionDetail } from '../../types/DistributionDetail'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/QueryKeys'
import UserWalletService from '../../api/services/UserWalletService/UserWalletService'
import SendAssetToUserWithActiveWallet from '../SendAssetToUserWithActiveWallet/SendAssetToUserWithActiveWallet'
import UserService from '../../api/services/UserService/UserService'
import EmailSenderService from '../../api/services/EmailSenderService/EmailSenderService'
import { t } from 'i18next'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import { useCallback, useState } from 'react'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'
import PaymentWalletService from '../../api/services/PaymentWalletService/PaymentWalletService'

interface AdminSendAssetsProps {
  goBack: () => void
  shouldSendToAll: boolean
  distributionDetail: DistributionDetail
  onSuccess: () => void
  shouldSendAllPendingAssets?: boolean
  isInvestmentReturnDistribution: boolean
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

export default function AdminSendAssets(props: AdminSendAssetsProps) {
  const classes = useStyles()
  const readonly = [AssetDistributedStatus.InTransit, AssetDistributedStatus.Delivered].includes(
    props.distributionDetail.status
  )
  const activeWalletResponse = useQuery(
    QueryKeys.getFetchUserWalletsKey(
      props.distributionDetail.userId,
      props.distributionDetail.tokenId
    ),
    async () =>
      await UserWalletService.getUserWallets(
        props.distributionDetail.userId,
        props.distributionDetail.tokenId,
        true
      ),
    { enabled: !props.shouldSendToAll || readonly }
  )
  const walletAddress = activeWalletResponse?.data

  const paymentWalletBalanceResponse = useQuery<string, Error>(
    QueryKeys.getPaymentWalletBalanceKey(),
    async () => {
      return await PaymentWalletService.getPaymentWalletBalance()
    }
  )
  const paymentWalletBalance = paymentWalletBalanceResponse?.data

  const [emailResponseMessage, setEmailResponseMessage] = useState<string>('')
  const [isErrorMessage, setIsErrorMessage] = useState<boolean>(false)
  const noWalletAssignedCallback = useCallback(async () => {
    const { email, userName } = await UserService.get(props.distributionDetail.userId)
    const mailSenderResponse = await EmailSenderService.sendRequestAssociateWalletEmail(
      email,
      t('¡Aún no tienes una wallet X registrada!', {
        tokenId: props.distributionDetail.tokenId?.toUpperCase(),
      }),
      'requestassociatewallet',
      userName.name,
      props.distributionDetail.tokenId
    )
    if (mailSenderResponse.statusCode !== HTTPStatusCodes.OK) {
      setEmailResponseMessage(mailSenderResponse.msg)
      setIsErrorMessage(true)
    } else {
      setEmailResponseMessage(t('Mail enviado con éxito'))
      setIsErrorMessage(false)
    }
  }, [props.distributionDetail.userId, props.distributionDetail.tokenId])

  return (
    <Paper elevation={0}>
      <Card
        sx={{ minWidth: 275, backgroundColor: Constants.GRAY_RGB }}
        className={classes.principalCard}
      >
        <CardContent sx={{ width: '100%' }}>
          {activeWalletResponse.isLoading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
          ) : props.shouldSendToAll || (walletAddress && walletAddress.length > 0) ? (
            <SendAssetToUserWithActiveWallet
              distributionDetail={props.distributionDetail}
              goBack={props.goBack}
              shouldSendToAll={props.shouldSendToAll}
              readonly={readonly}
              onSuccess={props.onSuccess}
              shouldSendAllPendingAssets={props.shouldSendAllPendingAssets}
              paymentWalletBalance={paymentWalletBalance || ''}
              isInvestmentReturn={props.isInvestmentReturnDistribution}
            />
          ) : (
            <SendAssetToUserWithoutActiveWallet
              distributionDetail={props.distributionDetail}
              noWalletAssignedCallback={noWalletAssignedCallback}
              goBack={props.goBack}
              callbackStatusMessage={emailResponseMessage}
              isErrorMessage={isErrorMessage}
            />
          )}
        </CardContent>
      </Card>
    </Paper>
  )
}
