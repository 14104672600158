import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Button, TextField, Grid, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import ConfirmationDistributeAssetsDialog from './ConfirmationDistributeAssetsDialog'
import { useState, useContext } from 'react'
import DatesPicker from '../DatesPicker/DatesPicker'
import Constants from '../../utils/Constants'
import { TotalTokenTenency } from '../../types/TotalTokenTenency'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery } from 'react-query'
import { AppContext } from '../../contexts/AppContext'
import { useNavigate } from 'react-router-dom'

interface AdminDistributeAssetsProps {
  onSubmitForm: Function
  initialDateFrom?: Date
  initialDateTo?: Date
}

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

const BTC_DECIMALS_REGEX = /^(?:\d*\.\d{1,8}|\d+)$/

export default function AdminDistributeAssetsForm({
  onSubmitForm,
  initialDateFrom,
  initialDateTo,
}: AdminDistributeAssetsProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [amountToDistribute, setAmountToDistribute] = useState<number | undefined>(0)
  const [btcPerMinen, setBtcPerMinen] = useState<number | undefined>(0)
  const [dateFrom, setDateFrom] = useState<Date | undefined>(initialDateFrom)
  const [dateTo, setDateTo] = useState<Date | undefined>(initialDateTo)
  const [MINENUnit, setMINENUnit] = useState<number | undefined>(0)
  const [mailErrorsList, setMailErrorsList] = useState<string[]>([])
  const [distributeError, setDistributeError] = useState<string>('')
  const [isDistributeCompleted, setIsDistributeComplete] = useState<boolean>(false)
  const totalMinenTenency = useQuery<TotalTokenTenency, Error>(
    QueryKeys.getFetchTotalTokenTenencyKey(Constants.MINEN_SYMBOL),
    async () => {
      return await AssetsService.getTotalTokenTenency(Constants.MINEN_SYMBOL)
    }
  )
  const { user } = useContext(AppContext)
  const navigate = useNavigate()

  const cleanState = () => {
    setAmountToDistribute(undefined)
    setBtcPerMinen(undefined)
    setMINENUnit(undefined)
    setDateFrom(undefined)
    setDateTo(undefined)
  }

  const distributeAssets = async () => {
    let assetsServiceResponse
    if (amountToDistribute) {
      assetsServiceResponse = await AssetsService.distributeAssets({
        totalAmount: amountToDistribute,
        dateTo: dateTo,
        dateFrom: dateFrom,
        adminId: user?.id,
      })
      if (assetsServiceResponse.statusCode !== HTTPStatusCodes.OK) {
        setDistributeError(assetsServiceResponse.msg)
      } else {
        if (assetsServiceResponse.data.distributionId) {
          navigate(`/admin/distribute/${assetsServiceResponse.data.distributionId}`)
        }
        setMailErrorsList(assetsServiceResponse.data.failedEmails || [])
      }
      setIsDistributeComplete(true)
      cleanState()
    }
  }

  const getValidationSchema = () => {
    return yup.object({
      totalAmount: yup
        .number()
        .typeError(t('Debe ingresar un numero'))
        .positive(t('Total a distribuir debe ser mayor a 0'))
        .required(t('Este campo es requerido')),
      dateFrom: yup
        .date()
        .typeError(t('Formato de fecha inválido'))
        .required(t('Este campo es requerido')),
      dateTo: yup
        .date()
        .typeError(t('Formato de fecha inválido'))
        .min(yup.ref('dateFrom'), t('La fecha final debe ser mayor a la fecha inicial'))
        .required(t('Este campo es requerido')),
    })
  }

  return (
    <Grid>
      <Typography variant="subtitle2" mb={1}>
        {t('A distribuir entre X MINEN', {
          totalTenency: totalMinenTenency?.data?.totalTenency || '-',
        })}
      </Typography>
      <Formik
        initialValues={{ totalAmount: 0, dateFrom: initialDateFrom, dateTo: initialDateTo }}
        validationSchema={getValidationSchema()}
        onSubmit={async (values, actions) => {
          if (!BTC_DECIMALS_REGEX.test(values.totalAmount.toString())) {
            actions.setFieldError(
              'totalAmount',
              t('El valor no puede tener más de X decimales', {
                decimals: Constants.BITCOIN_DECIMALS,
              })
            )
            return
          }
          const assetsServiceResponse = await onSubmitForm(values.totalAmount)
          if (assetsServiceResponse.statusCode !== HTTPStatusCodes.OK) {
            actions.setStatus(assetsServiceResponse.msg)
          } else {
            setBtcPerMinen(assetsServiceResponse.data?.btcPerMinen)
            setAmountToDistribute(values.totalAmount)
            setMINENUnit(1)
            setDateFrom(values.dateFrom)
            setDateTo(values.dateTo)
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DatesPicker formik={formik} />
            <TextField
              fullWidth
              margin="dense"
              id="totalAmount"
              name="totalAmount"
              label={t('Total cantidad a distribuir')}
              type="double"
              value={formik.values.totalAmount}
              onChange={formik.handleChange}
              error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
              sx={{ marginTop: 3 }}
            />
            {formik.touched.totalAmount && formik.errors.totalAmount && (
              <div className={classes.error}>{formik.errors.totalAmount}</div>
            )}
            <Grid sx={{ mb: 1 }} className={classes.error}>
              {formik.status}
            </Grid>
            <Button
              disabled={formik.isSubmitting}
              type="submit"
              fullWidth
              sx={{ mt: 1 }}
              color="primary"
              variant="contained"
            >
              {formik.isSubmitting ? <CircularProgress size={26} color="inherit" /> : t('Enviar')}
            </Button>
            {Boolean(amountToDistribute && btcPerMinen && MINENUnit) && (
              <ConfirmationDistributeAssetsDialog
                show={Boolean(amountToDistribute && btcPerMinen && MINENUnit)}
                BTCPerMinen={btcPerMinen}
                unitMINEN={MINENUnit}
                onClose={cleanState}
                onConfirm={distributeAssets}
              />
            )}
          </Form>
        )}
      </Formik>
      {isDistributeCompleted && (
        <Grid sx={{ pt: 2 }}>
          {distributeError ? (
            distributeError
          ) : (
            <Grid className={classes.succeed}> {t('Distribucion OK')} </Grid>
          )}
          {mailErrorsList.length > 0 && (
            <Grid sx={{ mt: 2 }} className={classes.error}>
              {' '}
              {t('Distribucion Error', { errorMails: mailErrorsList.join(' ') })}{' '}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}
