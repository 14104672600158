import { apiClient } from '../../axios'
import {
  getLoginUrl,
  getLogoutUrl,
  getChangePasswordUrl,
  getForgotPasswordUrl,
  getResetPasswordUrl,
  getValidatePasswordUrl,
  getAuthUserUrl,
  getRegisterUrl,
} from './AuthServiceUrls'
import { t } from 'i18next'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'
import { LoginData } from '../../../types/LoginData'
import { ChangePasswordData } from '../../../types/ChangePasswordData'
import { UserLoggedIn } from '../../../types/UserLoggedIn'
import { CreateUserBody } from '../../../types/CreateUserBody'

const login = async (loginData: LoginData) => {
  let res
  try {
    res = await apiClient.post(getLoginUrl(), loginData)
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      msg: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : t('Error'),
    }
  }
}

const getAccessToken = (): string => {
  return ''
}

const logout = async () => {
  await apiClient.post(getLogoutUrl(), {})
}

const changeUserPassword = async (changePasswordData: ChangePasswordData) => {
  let res
  try {
    res = await apiClient.put(getChangePasswordUrl(), changePasswordData)
    return {
      statusCode: res.status,
      msg: res.data.msg,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      messages: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : [t('Error')],
    }
  }
}

const forgotPassword = async (email: string) => {
  try {
    const response = await apiClient.post(getForgotPasswordUrl(), { email })
    return response.status === HTTPStatusCodes.OK
  } catch (e: any) {
    return false
  }
}

const resetPassword = async (newPassword: string, resetPasswordToken: string, email: string) => {
  try {
    const response = await apiClient.post(getResetPasswordUrl(), {
      newPassword,
      resetPasswordToken,
      email,
    })
    return response.status === HTTPStatusCodes.OK
  } catch (e: any) {
    return false
  }
}

const validatePassword = async (password: string) => {
  try {
    const res = await apiClient.get(getValidatePasswordUrl(password))
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      data: [t('Error')],
    }
  }
}

const getUserIsAdmin = async (userId: string) => {
  try {
    const res = await apiClient.get<UserLoggedIn>(getAuthUserUrl(userId))
    if (res.status === HTTPStatusCodes.OK) {
      return res.data.isAdmin
    }
    return false
  } catch (e: any) {
    return false
  }
}

const updateAuthUser = async (userId: string, isAdmin: boolean) => {
  try {
    const res = await apiClient.put(getAuthUserUrl(userId), { isAdmin })
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      data: [t('Error')],
    }
  }
}

const createAuthUser = async (user: CreateUserBody) => {
  try {
    const res = await apiClient.post(getRegisterUrl(), user)
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      data: [t('Error')],
    }
  }
}

const AuthService = {
  login,
  logout,
  changeUserPassword,
  getAccessToken,
  forgotPassword,
  resetPassword,
  validatePassword,
  getUserIsAdmin,
  updateAuthUser,
  createAuthUser,
}

export default AuthService
