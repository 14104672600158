import { Alert, Grid, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { User } from '../../types/User'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery, useQueries } from 'react-query'
import UserService from '../../api/services/UserService/UserService'
import UserManagement from '../../components/UserManagement/UserManagement'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import Constants from '../../utils/Constants'
import UserWalletService from '../../api/services/UserWalletService/UserWalletService'
import { t } from 'i18next'
import AuthService from '../../api/services/AuthService/AuthService'
import { AssetDistribution } from '../../types/AssetDistribution'
import { useMemo } from 'react'
import { AssetWallet } from '../../types/AssetWallet'

const getAmountAssigned = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountAssigned : 0
}

const getAmountToDistribute = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountToDistribute : 0
}

const getAmountRequested = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountRequested : 0
}

export default function AdminUserManagementPage() {
  const { userId } = useParams()
  const nonNullUserId = userId!
  const userResponse = useQuery<User, Error>(QueryKeys.getFetchUserKey(nonNullUserId), async () => {
    return await UserService.get(nonNullUserId)
  })
  const user = userResponse?.data
  const userAssetsResponse = useQuery(
    QueryKeys.getFetchUserAssetsKey(nonNullUserId),
    async () => await AssetsService.getAssets(nonNullUserId)
  )
  const amountOfMinen = getAmountAssigned(Constants.MINEN_SYMBOL, userAssetsResponse.data)
  const amountOfMinenToDistribute = getAmountToDistribute(
    Constants.MINEN_SYMBOL,
    userAssetsResponse.data
  )
  const amountOfMinenRequested = getAmountRequested(Constants.MINEN_SYMBOL, userAssetsResponse.data)
  const amountOfBtc = getAmountAssigned(Constants.BITCOIN_SYMBOL, userAssetsResponse.data)
  const amountOfBtcToDistribute = getAmountToDistribute(
    Constants.BITCOIN_SYMBOL,
    userAssetsResponse.data
  )
  const amountOfBtcRequested = getAmountRequested(Constants.BITCOIN_SYMBOL, userAssetsResponse.data)

  const isAdminResponse = useQuery<boolean, Error>(
    QueryKeys.getFetchAuthUserKey(nonNullUserId),
    async () => await AuthService.getUserIsAdmin(nonNullUserId)
  )
  const isAdmin = isAdminResponse?.data || false

  const userBtcDistributionsResponse = useQuery<AssetDistribution[], Error>(
    QueryKeys.getFetchAssetsDistributionsKey(Constants.BITCOIN_SYMBOL, userId),
    async () => await AssetsService.getDistributions(Constants.BITCOIN_SYMBOL, userId)
  )

  const userBtcDistributions = useMemo(
    () => (userBtcDistributionsResponse.data ? userBtcDistributionsResponse.data : []),
    [userBtcDistributionsResponse]
  )

  const assets = useMemo<string[]>(
    () => (Boolean(userAssetsResponse?.data) ? Object.keys(userAssetsResponse?.data) : []),
    [userAssetsResponse?.data]
  )

  const userAssetWalletsResponse = useQueries(
    assets.map((asset) => {
      return {
        queryKey: ['asset.tokenId', QueryKeys.getFetchUserWalletsKey(nonNullUserId, asset)],
        queryFn: () => UserWalletService.getUserWallets(nonNullUserId, asset, true),
      }
    })
  )

  const wallets = useMemo<AssetWallet[]>(() => {
    return userAssetWalletsResponse.map((response) => {
      return {
        asset: response?.data?.at(0)?.tokenId,
        walletAddress: response?.data?.at(0)?.address,
      }
    })
  }, [userAssetWalletsResponse])

  useMemo(() => {
    assets.forEach((assetId) => {
      if (!wallets.find((wallet) => wallet.asset === assetId)) {
        wallets.push({ asset: assetId, walletAddress: undefined })
      }
    })
  }, [assets, wallets])

  const onRewardSent = () => {
    userAssetsResponse.refetch()
    userBtcDistributionsResponse.refetch()
  }

  const onAssetSent = () => {
    userAssetsResponse.refetch()
  }

  return (
    <Grid container>
      <Grid item width="100%">
        {userResponse.isLoading ||
        userAssetsResponse.isLoading ||
        userAssetWalletsResponse.find((response) => response.isLoading) ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
        ) : user ? (
          <UserManagement
            asset={Constants.MINEN_SYMBOL}
            assetAmount={amountOfMinen}
            isAdmin={isAdmin}
            user={user}
            assetRewarded={Constants.BITCOIN_SYMBOL}
            rewardAmount={amountOfBtc}
            distributions={userBtcDistributions}
            rewardAmountPendingToSend={amountOfBtcToDistribute + amountOfBtcRequested}
            onRewardSent={onRewardSent}
            onAssetSent={onAssetSent}
            assetAmountPendingToSend={amountOfMinenToDistribute + amountOfMinenRequested}
            assetWallets={wallets}
          />
        ) : (
          <Alert variant="filled" severity="error">
            {t(
              'Hubo un error al obtener la información del usuario X. Por favor, intente nuevamente o contacte al administrador.',
              { userId }
            )}
          </Alert>
        )}
      </Grid>
    </Grid>
  )
}
