import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Button, TextField, Grid, CircularProgress, Typography, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Constants from '../../utils/Constants'
import { TokenData } from '../../types/TokenData'
import { useDialog } from '../../utils/hooks/UseDialog'
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog'

interface AdminCreateTokenFormProps {
  onSubmitForm: (data: TokenData) => Promise<boolean>
  symbols: string[]
}

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

const tokenTypesForCreation = ['OPEN', 'CLOSED']

export default function AdminCreateTokenForm(props: AdminCreateTokenFormProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [openDialog, closeDialog] = useDialog()

  return (
    <Grid>
      <Typography variant="h5" mb={1}>
        {t('Crear token')}
      </Typography>
      <Formik
        initialValues={{
          tokenName: '',
          tokenSymbol: '',
          tokenType: '',
          tokenSupply: '',
          tokenDecimals: '18',
        }}
        validationSchema={yup.object({
          tokenName: yup
            .string()
            .max(20, t('El nombre no puede tener más de X carácteres', { max: 20 }))
            .required(t(Constants.REQUIRED_FIELD_TEXT)),
          tokenSymbol: yup
            .string()
            .max(6, t('El símbolo no puede tener más de X carácteres', { max: 6 }))
            .required(t(Constants.REQUIRED_FIELD_TEXT)),
          tokenSupply: yup
            .number()
            .typeError(t('Debe ingresar un numero'))
            .positive(t('Debe ser mayor a 0'))
            .max(1000000, t('El valor máximo es X', { max: 1000000 }))
            .integer(t('No se admiten números decimales'))
            .required(t(Constants.REQUIRED_FIELD_TEXT)),
          tokenDecimals: yup
            .number()
            .typeError(t('Debe ingresar un numero'))
            .positive(t('Debe ser mayor a 0'))
            .max(18, t('El valor máximo es X', { max: 18 }))
            .required(t(Constants.REQUIRED_FIELD_TEXT)),
          tokenType: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
        })}
        onSubmit={async (values, actions) => {
          if (props.symbols.includes(values.tokenSymbol.toUpperCase())) {
            actions.setFieldError('tokenSymbol', t('El símbolo ya se encuentra en uso'))
            return
          }
          openDialog({
            children: (
              <ConfirmationDialog
                message={t('¿Seguro que desea crear el token X?', { tokenName: values.tokenName })}
                onConfirm={async () => {
                  actions.setSubmitting(true)
                  closeDialog()
                  const tokenData = {
                    name: values.tokenName,
                    symbol: values.tokenSymbol,
                    type: values.tokenType,
                    totalSupply: values.tokenSupply,
                    decimals: values.tokenDecimals,
                  }
                  const didSucceed = await props.onSubmitForm(tokenData)
                  actions.setSubmitting(false)
                  if (didSucceed) {
                    actions.resetForm()
                  }
                }}
                onCancel={closeDialog}
              />
            ),
          })
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="tokenName"
              name="tokenName"
              label={t('Nombre del token')}
              value={formik.values.tokenName}
              onChange={formik.handleChange}
              error={formik.touched.tokenName && Boolean(formik.errors.tokenName)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.tokenName && formik.errors.tokenName && (
              <div className={classes.error}>{formik.errors.tokenName}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="tokenSymbol"
              name="tokenSymbol"
              label={t('Símbolo del token')}
              value={formik.values.tokenSymbol}
              onChange={formik.handleChange}
              error={formik.touched.tokenSymbol && Boolean(formik.errors.tokenSymbol)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.tokenSymbol && formik.errors.tokenSymbol && (
              <div className={classes.error}>{formik.errors.tokenSymbol}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="tokenType"
              select
              name="tokenType"
              label={t('Tipo de token')}
              value={formik.values.tokenType}
              onChange={formik.handleChange}
              error={formik.touched.tokenType && Boolean(formik.errors.tokenType)}
              sx={{ marginTop: 1 }}
              helperText={t(
                'Cerrado: Solo se puede transferir a quien disponga el token. Abierto: Se puede transferir libremente.'
              )}
            >
              {tokenTypesForCreation.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </TextField>
            {formik.touched.tokenType && formik.errors.tokenType && (
              <div className={classes.error}>{formik.errors.tokenType}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="tokenSupply"
              name="tokenSupply"
              label={t('Cantidad de tokens')}
              type="number"
              value={formik.values.tokenSupply}
              onChange={formik.handleChange}
              error={formik.touched.tokenSupply && Boolean(formik.errors.tokenSupply)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.tokenSupply && formik.errors.tokenSupply && (
              <div className={classes.error}>{formik.errors.tokenSupply}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="tokenDecimals"
              name="tokenDecimals"
              label={t('Cantidad de decimales')}
              type="number"
              value={formik.values.tokenDecimals}
              onChange={formik.handleChange}
              error={formik.touched.tokenDecimals && Boolean(formik.errors.tokenDecimals)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.tokenDecimals && formik.errors.tokenDecimals && (
              <div className={classes.error}>{formik.errors.tokenDecimals}</div>
            )}
            <Button
              disabled={formik.isSubmitting}
              type="submit"
              fullWidth
              sx={{ mt: 1 }}
              color="primary"
              variant="contained"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={26} color="inherit" />
              ) : (
                t('Crear token')
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
