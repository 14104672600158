import { Navigate } from 'react-router'

interface PageNotFoundProps {
  isAuthenticated: boolean
  userId?: string
}

export function PageNotFound(props: PageNotFoundProps) {
  return props.isAuthenticated ? (
    <Navigate to={`/user-wallet`} replace />
  ) : (
    <Navigate to="/login" replace />
  )
}
